<template>
  <section class="page-landing">

    <!-- Get Started -->
    <transition name="discover">
      <landing-layout v-if="appState === 1" :class="{'is-animated discover': discover}" :app-state="appState">
        <template v-slot:pageContent>
          <get-started  @setAppState="setAppState"></get-started>
        </template>
      </landing-layout>
    </transition>

    <!-- Choose Tempo -->
    <transition name="fade">
      <landing-layout v-if="appState === 2" :app-state="appState">
        <template v-slot:pageSidepanel>
          <steps-line :appState="1"></steps-line>
        </template>
        <template v-slot:pageContent>
          <choose-tempo @createOrder="createOrder"></choose-tempo>
        </template>
      </landing-layout>
    </transition>

    <!-- Set Width Tempo -->
    <transition name="fade">
      <landing-layout v-if="appState === 3" :app-state="appState">
        <template v-slot:pageSidepanel>
          <steps-line :appState="2"></steps-line>
        </template>
        <template v-slot:pageContent>
          <set-width @updateOrder="updateOrder" :order="order"></set-width>
        </template>
      </landing-layout>
    </transition>

    <!-- Set Length Tempo -->
    <transition name="fade">
      <landing-layout v-if="appState === 4" :app-state="appState">
        <template v-slot:pageSidepanel>
          <steps-line :appState="2"></steps-line>
        </template>
        <template v-slot:pageContent>
          <set-length @updateOrder="updateOrder" :order="order"></set-length>
        </template>
      </landing-layout>
    </transition>

    <!-- Set Height Tempo -->
    <transition name="fade">
      <landing-layout v-if="appState === 5" :app-state="appState">
        <template v-slot:pageSidepanel>
          <steps-line :appState="2"></steps-line>
        </template>
        <template v-slot:pageContent>
          <set-height @updateOrder="updateOrder" :order="order"></set-height>
        </template>
      </landing-layout>
    </transition>

    <!-- Confirm Dimensions Tempo -->
    <transition name="fade">
      <landing-layout v-if="appState === 6" :app-state="appState">
        <template v-slot:pageSidepanel>
          <steps-line :appState="3"></steps-line>
        </template>
        <template v-slot:pageContent>
          <Confirm-dimensions @updateOrder="updateOrder" :order="order"></Confirm-dimensions>
        </template>
      </landing-layout>
    </transition>

    <!-- Confirm Quantity -->
    <transition name="fade">
      <landing-layout v-if="appState === 7" :app-state="appState">
        <template v-slot:pageSidepanel>
          <steps-line :appState="3"></steps-line>
        </template>
        <template v-slot:pageContent>
          <Confirm-quantity @updateOrder="updateOrder" :order="order"></Confirm-quantity>
        </template>
      </landing-layout>
    </transition>

    <!-- Confirm Command -->
    <transition name="fade">
      <landing-layout v-if="appState === 8" :app-state="appState">
        <template v-slot:pageSidepanel>
          <steps-line :appState="4"></steps-line>
        </template>
        <template v-slot:pageContent>
          <confirm-command @updateOrder="updateOrder" :order="order"></confirm-command>
        </template>
      </landing-layout>
    </transition>

    <!-- Choose delivery -->
    <transition name="fade">
      <landing-layout v-if="appState === 9" :appState="appState">
        <template v-slot:pageSidepanel>
          <steps-line :appState="4"></steps-line>
        </template>
        <template v-slot:pageContent>
          <delivery-mode @updateOrder="updateOrder"></delivery-mode>
        </template>
      </landing-layout>
    </transition>

    <!-- domicile deliver -->
    <transition name="fade">
      <landing-layout v-if="appState === 10 && this.order.delivery === 'domicile' " :appState="appState">
        <template v-slot:pageSidepanel>
          <steps-line :appState="4"></steps-line>
        </template>
        <template v-slot:pageContent>
          <domicile-deliver @updateOrder="updateOrder" :order="order"></domicile-deliver>
        </template>
      </landing-layout>
    </transition>

    <!-- shop deliver -->
    <transition name="fade">
      <landing-layout v-if="appState === 10 && this.order.delivery === 'magasin' " :appState="appState">
        <template v-slot:pageSidepanel>
          <steps-line :appState="4"></steps-line>
        </template>
        <template v-slot:pageContent>
          <shop-deliver @updateOrder="updateOrder"></shop-deliver>
        </template>
      </landing-layout>
    </transition>

    <!-- choose paiement -->
    <transition name="fade">
      <landing-layout v-if="appState === 11" :appState="appState">
        <template v-slot:pageSidepanel>
          <steps-line :appState="4"></steps-line>
        </template>
        <template v-slot:pageContent>
          <choose-paiement @updateOrder="updateOrder"></choose-paiement>
        </template>
      </landing-layout>
    </transition>

    <!-- Final Paiement -->
    <transition name="fade">
      <landing-layout v-if="appState === 12"  :appState="appState">
        <template v-slot:pageSidepanel>
          <steps-line :appState="4"></steps-line>
        </template>
        <template v-slot:pageContent>
          <final-paiement @updateOrder="updateOrder" :order="order"></final-paiement>
        </template>
      </landing-layout>
    </transition>

    <!-- Thank You -->
    <transition name="fade">
      <landing-layout v-if="appState === 13" :appState="appState">
        <template v-slot:pageSidepanel>
          <steps-line :appState="4"></steps-line>
        </template>
        <template v-slot:pageContent>
          <thank-you></thank-you>
        </template>
      </landing-layout>
    </transition>

  </section>
</template>

<script>
  import {mapState, mapActions} from 'vuex'

  // Layout Components
  import LandingLayout from '@/components/layouts/LandingLayout'

  // Partials Components
  import GetStarted from '@/components/pages/landing/GetStarted'
  import ChooseTempo from '@/components/pages/landing/ChooseTempo'
  import SetWidth from '@/components/pages/landing/SetWidth'
  import SetLength from '@/components/pages/landing/SetLength'
  import SetHeight from '@/components/pages/landing/SetHeight'
  import StepsLine from '@/components/partials/StepsLine'
  import ConfirmDimensions from '@/components/pages/landing/ConfirmDimensions'
  import ConfirmQuantity from '@/components/pages/landing/ConfirmQuantity'
  import ConfirmCommand from '@/components/pages/paiement/ConfirmCommand'
  import DeliveryMode from '@/components/pages/paiement/DeliveryMode'
  import DomicileDeliver from '@/components/pages/paiement/DomicileDeliver'
  import ShopDeliver from '@/components/pages/paiement/ShopDeliver'
  import ChoosePaiement from '@/components/pages/paiement/ChoosePaiement'
  import FinalPaiement from '@/components/pages/paiement/FinalPaiement'
  import ThankYou from '@/components/pages/paiement/ThankYou'

  // DB Components
  import OrderDB from "@/firebase/order-db"

  export default {
    head() {
      return {
        title: {
          inner: 'Boutique'
        },
        meta: [
          // Global Metas OG
          { name: 'application-name', content: `${this.appTitle}` },
          {
            name: 'description',
            content: `${
              this.appTitle
              } Pionnier dans la conception et la fabrication d&#039;abris d&#039;auto au Québec, Abris Tempo facilite l&#039;hiver de nombreux Québécois grâce à ses abris d&#039;auto.`,
            id: 'desc'
          },
          // Twitter OG
          { name: 'twitter:title', content: `${this.appTitle}` },
          {
            n: 'twitter:description',
            c: `${
              this.appTitle
              } Pionnier dans la conception et la fabrication d&#039;abris d&#039;auto au Québec, Abris Tempo facilite l&#039;hiver de nombreux Québécois grâce à ses abris d&#039;auto.`
          },
          // Facebook / Open Graph
          { property: 'og:title', content: `${this.appTitle}` },
          { property: 'og:site_name', content: `${this.appTitle}` },
          {
            property: 'og:description',
            content: `${
              this.appTitle
              } Pionnier dans la conception et la fabrication d&#039;abris d&#039;auto au Québec, Abris Tempo facilite l&#039;hiver de nombreux Québécois grâce à ses abris d&#039;auto.`
          },
          // Og Image
          {
            p: 'og:image',
            c:
              ''
          }
        ]
      }
    },
    components: {
      LandingLayout,
      GetStarted,
      StepsLine,
      ChooseTempo,
      SetLength,
      SetHeight,
      ConfirmDimensions,
      ConfirmQuantity,
      SetWidth,
      ConfirmCommand,
      DeliveryMode,
      ShopDeliver,
      DomicileDeliver,
      FinalPaiement,
      ThankYou,
      ChoosePaiement
    },
    data() {
      return {
        discover: false,
        appState: 1
      }
    },
    computed: {
      ...mapState('app', ['appTitle']),
      ...mapState('orders', ['order']),
    },
    methods: {
      handleAnimation() {
        // this.discover = window.innerWidth > 1024;
      },
      async createOrder(order, step) {

        const orderRef = new OrderDB()
        const newOrder = await orderRef.create({order, step})

        this.setAppState(3, newOrder)
      },
      async updateOrder(order, step) {

        const data = {
          ...this.order,
          ...order
        }

        if (step === 13) {
          const orderRef = new OrderDB()

          await orderRef.update(data)
        }

        this.setAppState(step, data)
      },
      setAppState(appState, order) {

        if (order) {
          this.setOrder(order)
        }

        this.appState = appState
      },
      ...mapActions('orders', ['setOrder']),
      ...mapActions('app', ['setProducts'])
    },
    mounted() {
      this.handleAnimation()
      console.log('before')
      this.setProducts()
      console.log('after')
      window.addEventListener('resize', this.handleAnimation)
    }
  }
</script>

<style lang="scss">
</style>
