<template>
  <div class="pay-tempo-container">
    <div class="tempo-header-steps">
      <p class="macaron-step">ÉTAPE 4</p>
      <p class="step-state">Question 1/5</p>
      <h1 class="medium-title pay-tempo-heading">Confirmation de commande</h1>
    </div>
    <div class="help-box">
      <p class="help-box-text">Les frais de livraison, s’il y a lieu, sont calculés à l’étape suivante.</p>
    </div>
    <div class="resume-command">
      <div class="resume-img-container">
        <img :src=" '../../../img/types/' + order.order.image + '.jpg' " alt="">
      </div>
      <div class="resume-command-infos">
        <h3 class="medium-title resume-command-title">Toile Tempo pour {{ order.category }}</h3>
        <p class="regular-text resume-command-specs">{{ order.description }} - Ref : {{ order.ref }}</p>
        <p class="small-text resume-size">Largeur : {{ order.width }}’</p>
        <p class="small-text resume-size">Longueur : {{ order.length }}’</p>
        <p class="small-text resume-size">Hauteur : {{ order.height }}”</p>
      </div>
      <div class="resume-command-total">
        <div class="resume-command-number">
          <div class="minus-one">-</div>
          <input type="number" :value=" order.quantity " disabled>
          <div class="plus-one">+</div>
        </div>
        <p class="big-text resume-command-price">{{ order.price }}$</p>
      </div>
    </div>
    <div class="calcul-taxes-wrapper">
      <p class="small-text calcul-taxes sous-total">Sous-total HT <span>{{ order.price }} $</span></p>
      <p class="small-text calcul-taxes tps">TPS <small>803335058RT001</small> (5%) <span>{{ TPS }} $</span></p>
      <p class="small-text calcul-taxes tvq">TVQ <small>1216664821TQ0001</small> (9,975%) <span>{{ TVQ }} $</span></p>
      <p class="small-text calcul-taxes total">TOTAL <span>{{ priceTTC }} $</span></p>
    </div>
    <div class="navbar-wrapper">
      <a @click.prevent="handlePrev" href="#" class="site-btn is-revert">Précédent</a>
      <a @click.prevent="handleOrder" href="#" class="site-btn">Continuer</a>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      appState: {
        type: Number,
        default: 2
      },
      order: Object
    },
    data() {
      return {
        step: 8,
        orderIsConfimed: false,
        priceTTC: '',
        prevStep: '',
        nextStep: '',
        TVQ: '',
        TPS: '',
        delivery: '100'
      }
    },
    mounted() {
      this.calculTTC(this.order.price)
    },
    methods: {
      handlePrev() {
        this.prevStep = this.step - 1

        this.$emit('updateOrder', { }, this.prevStep)
      },
      handleOrder() {
        this.orderIsConfimed = true
        this.nextStep = this.step + 1
        this.$emit('updateOrder', {orderIsConfimed: this.orderIsConfimed, priceTTC: this.priceTTC}, this.nextStep)
      },
      calculTTC(price) {

        this.TVQ = this.order.price * 0.09975

        this.TVQ = parseFloat(this.TVQ).toFixed(2)

        this.TPS = this.order.price * 0.05

        this.TPS = parseFloat(this.TPS).toFixed(2)

        this.priceTTC += (price * 1.14975)

        this.priceTTC = parseFloat(this.priceTTC).toFixed(2)

      }
    }
  }
</script>

<style lang="scss">
  .calcul-taxes-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    max-width: 270px;
    align-self: flex-end;
    padding: 16px 0;

    .calcul-taxes {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;


      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: #6E6E7A;
      margin: 0;
      padding-top: 8px;

      &.total {
        font-weight: bold;
        font-size: 24px;
        border-top: 1px solid #F2F3F6;
        color: #333333;
        padding-top: 15px;
        margin-top: 16px;
        margin-bottom: 0;
        padding-bottom: 100px;
      }

      span {
      }
    }
  }
  .resume-command {
    display: flex;
    justify-content: space-around;
    background: #FFFFFF;

    border: 1px solid #D9D9D9;

    box-shadow: 0px 1px 2px #E1E0E3;
    border-radius: 8px;
    padding: 16px 18px;
    overflow: hidden;
    min-height: 250px;

    @media(max-width: 768px) {
      flex-direction: column;
    }

    .resume-img-container {
      max-width: 30%;
      margin-right: 32px;

      @media(max-width: 768px) {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
      }

      img {
        display: block;
        width: 100%;
      }
    }

    .resume-command-total {
      max-width: 20%;

      @media(max-width: 768px) {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
      }

      .resume-command-number {
        display: flex;
        background: #FFFFFF;

        border: 1px solid #D9D9D9;
        box-shadow: 0px 1px 2px #E1E0E3;
        border-radius: 8px;
        height: 44px;

        .minus-one {
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(100% / 3);
          border-right: 1px solid #D9D9D9;
        }

        input {
          width: calc(100% / 3);
          background-color: #fff;
          border: none;
          padding: 0;
          padding-left: 15%;
        }

        .plus-one {
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(100% / 3);
          border-left: 1px solid #D9D9D9;
        }
      }

      .resume-command-price {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 150%;
        color: #333333;
        margin: 0;
        margin-top: 40%;
        text-align: right;

        @media(max-width: 768px) {
          margin-top: 10%;
        }
      }
    }

    .resume-command-infos {
      margin-right: 40px;
      max-width: 50%;

      @media(max-width: 768px) {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
      }

      .resume-command-title {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 133%;

        color: #333333;
        margin-top: 0;
        margin-bottom: 10px;
      }

      .resume-command-specs {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;

        color: #6E6E7A;
        margin-top: 0;
        margin-bottom: 24px;
      }

      resume-size {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #6E6E7A;
        margin-top: 0;
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }


  }
  .pay-tempo-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .pay-tempo-heading {

    }
  }
</style>
