<template>
  <div class="page-wrapper">
    <img class="logo" src="@/assets/img/logo-tempo-color.svg" alt="logo-tempo"/>
    <a class="tempo-website" rel="noopener" target="_blank" href="https://abristempo.com/">Retour au site web</a>

    <div v-if="isStage">
      <h1 class="home-page-title">Trouvez votre toile en quelques clics</h1>
      <p class="home-page-text">Nous avons besoin de quelques informations qui nous permettront de vous proposer la toile la plus adéquate à vos besoins.</p>
      <!-- <p>{{ window.location.href }}</p> -->
      <div class="home-top-content">
        <h2 class="home-content-title">Trouvez votre toile</h2>
        <p class="home-content-text">Nos spécialistes sont disponibles pour répondre à toutes vos questions. N’hésitez pas à nous contacter via notre formulaire de contact.</p>
        <a @click="startForm" class="site-btn">Continuer</a>
      </div>
      <hr>
      <div class="home-bottom-content">
        <h2 class="home-content-title">Vous n’avez pas d’abri Tempo ?</h2>
        <a
          rel="noopener"
          class="site-btn is-revert"
          target="_blank"
          href="https://abristempo.com/en/contact-us/"
        >Contactez-nous</a>
      </div>
    </div>

    <div v-else>
      <h1 class="home-page-title">La boutique est présentement fermée</h1>
      <a href="http://abristempo.com/" class="site-btn">Visitez le site web</a>
    </div>
  </div>
</template>

<script>
  // Vuelidate validators
  import { required, email} from "vuelidate/src/validators"

  export default {
    data() {
      return {
        title: ``,
        text: ``,
        label: '',
        btnText: '',
        isStage: window.location.href.includes('web.app') || window.location.href.includes('localhost'),
        user: {
          email: ''
        }
      }
    },
    validations: {
      user: {
        email: {
          required,
          email
        }
      }
    },
    methods: {
      startForm() {
        this.$emit('setAppState', 2, null)
      }
    }
  }
</script>

<style lang="scss">
  .logo {
    position: absolute;
    left: 40px;
    top: 40px;
    margin-bottom: 3rem;

    @media(max-width: 1024px) {
      display: none;
    }
  }

  .page-wrapper {
    @media(max-width: 1024px) {
      padding: 40vw 15px;
    }
  }

  .logo-tempo-global {
    display: none;

    @media(max-width: 1024px) {
      display: block;
      position: absolute;
      top: 20px;
      left: 20px;
      margin-bottom: 3rem;
      max-width: 140px;
    }
  }

  .tempo-website {
    font-family: $fontText;
    position: absolute;
    top: 32px;
    right: 64px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: black;

    @media(max-width: 768px) {
      top: 20px;
      right: 20px;
    }
  }

  .home-page-title {
    font-family: $fontTitle;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 133%;

    color: #333333;
    max-width: 695px;
    text-align: center;
    margin: 0;
    margin-bottom: 20px;

    @media(max-width: 768px) {
      font-size: 30px;
    }
  }

  .home-page-text {
    font-family: $fontText;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;

    color: #6E6E7A;
    max-width: 664px;
    text-align: center;
  }

  .home-content-title {
    font-family: $fontTitle;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 133%;

    color: #EE1C24;
    width: 100%;
    max-width: 664px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 16px;
  }

  .home-content-text {
    font-family: $fontText;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;

    color: #6E6E7A;
    max-width: 664px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 16px;
  }

  .home-top-content {
    padding-top: 64px;
    padding-bottom: 32px;
    width: 100%;
    max-width: 664px;
  }

  .home-bottom-content {
    padding-top: 24px;
    width: 100%;
    max-width: 664px;
  }

  hr {
    border-top: 1px solid #F2F3F6;
    width: 100%;
    max-width: 664px;
  }
</style>
