<template>
  <div class="pay-tempo-container">
    <div class="tempo-header-steps">
      <p class="macaron-step">ÉTAPE 4</p>
      <p class="step-state">Question 2/5</p>
      <h1 class="medium-title pay-tempo-heading">Choix du mode de livraison</h1>
    </div>
    <div class="help-box has-max-width">
      <h2 class="help-box-heading">Note</h2>
      <p class="help-box-text">Vous pouvez être livré directement à votre domicile ou venir ramasser votre commande dans le magasin le plus proche.</p>
    </div>
    <div class="deliveries-wrapper">
      <div @click="setDelivery('domicile')" class="delivery-content">
        <div class="delivery-wrapper" :class="{'is-selected' :('domicile' === delivery) }">
          <h2 class="delivery-label">Livraison À domicile</h2>
          <svg width="76" height="76" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M37.995 8c-.553.011-1.117.21-1.663.569L2.039 35.84c-.88.88-1.501 2.777-.592 3.949.91 1.172 2.817 1.328 3.92.34l32.628-25.936L70.624 40.13c1.103.987 3.052.86 3.92-.341.76-1.055.574-3.072-.593-3.949L39.66 8.57c-.564-.398-1.11-.58-1.664-.569zM16.337 34.62l-5.414 4.318v26.335A2.716 2.716 0 0013.63 68h17.146a2.718 2.718 0 002.707-2.727v-10.91c0-2.563 1.967-4.545 4.513-4.545 2.545 0 4.512 1.982 4.512 4.546v10.909A2.716 2.716 0 0045.215 68h17.146a2.718 2.718 0 002.707-2.727V38.938l-5.414-4.319v27.926H47.922v-8.181c0-5.494-4.473-10-9.926-10-5.454 0-9.927 4.506-9.927 10v8.181H16.337V34.62z" fill="#6E6E7A"/></svg>
        </div>
      </div>
      <div @click="setDelivery('magasin')" class="delivery-content">
        <div class="delivery-wrapper" :class="{'is-selected' :('magasin' === delivery) }">
          <h2 class="delivery-label">ramassage en magasin</h2>
          <svg width="76" height="76" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M57.911 68.171a3.21 3.21 0 00-3.191 3.192 3.21 3.21 0 003.191 3.191 3.21 3.21 0 003.191-3.191 3.211 3.211 0 00-3.19-3.192zM23.711 8.511a6.99 6.99 0 01-6.991 6.992A6.99 6.99 0 019.729 8.51a6.99 6.99 0 016.99-6.991 6.99 6.99 0 016.992 6.991zM41.118 68.171a3.21 3.21 0 00-3.192 3.192 3.21 3.21 0 003.192 3.191 3.21 3.21 0 003.191-3.191c0-1.746-1.446-3.192-3.191-3.192z" fill="#6E6E7A"/><path d="M67.943 49.551c-.303-.457-.838-.683-1.369-.683l-27.131-.077v-6.763c0-.911-.76-1.67-1.672-1.748l-4.56-.077a3.646 3.646 0 00-.911-2.738L14.137 17.403c-1.368-1.52-3.648-1.597-5.168-.229-.912.838-1.217 1.672-1.14 3.192v50.997a3.65 3.65 0 003.648 3.649c1.978 0 3.27-1.14 3.65-3.649l1.368-10.64 1.671-12.389 1.823-13.602 6.917 7.6c.683.76 1.749 1.217 2.737 1.217l6.383.077v20.672c0 .911.76 1.748 1.749 1.748h24.32c.76 0 1.368-.457 1.671-1.217l4.483-13.757c.074-.532 0-1.063-.306-1.52zM44.84 62.623H39.37v-3.878l5.471.003v3.875zm0-6.612H39.37v-3.877h5.471v3.877zm9.12 6.611h-6.383v-3.877l6.383.003v3.875zm0-6.61h-6.383v-3.8h6.383v3.8zm6.84 6.61h-4.028v-3.877h5.32L60.8 62.623zm2.129-6.61h-6.232v-3.8h7.449l-1.217 3.8z" fill="#6E6E7A"/></svg>
        </div>
      </div>
    </div>
    <div class="navbar-wrapper">
      <a @click.prevent="handlePrev" href="#" class="site-btn is-revert">Précédent</a>
      <a href="#" class="site-btn" @click.prevent="handleOrder">Continuer</a>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      appState: {
        type: Number,
        default: 2
      }
    },
    data() {
      return {
        step: 9,
        delivery: '',
        nextStep: '',
        prevStep: ''
      }
    },
    methods: {
      setDelivery(deliver) {
        this.delivery = deliver
        this.nextStep = this.step + 1
      },
      handlePrev() {
        this.prevStep = this.step - 1

        this.$emit('updateOrder', { }, this.prevStep)
      },
      handleOrder() {
        if (this.delivery !== '') {
          this.$emit('updateOrder', {delivery: this.delivery}, this.nextStep)
        }
      }
    }
  }
</script>

<style lang="scss">
.help-box {
  &.has-max-width {
    max-width: 477px;
  }
}

.deliveries-wrapper{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  padding-bottom: 300px;

  .delivery-content {
    width: calc(100% / 2 - 8px);
    margin-right: 16px;
    margin-bottom: 16px;
    min-height: 181px;
    transition: transform .3s ease-in-out;
    cursor: pointer;

    svg {
      display: block;
      margin: 0 auto;
    }

    &:hover {
      transform: scale(1.06);
    }

    &:nth-child(2n) {
      margin-right: 0;
    }

    .delivery-wrapper {
      background: #FFFFFF;
      border: 1px solid #D9D9D9;
      box-sizing: border-box;
      border-radius: 8px;
      height: 100%;
      padding: 16px;

      &.is-selected {
        background: #FFE2E2;
        border: 1px solid #EE1C24;

        .delivery-label {
          color: #EE1C24;
        }

        .delivery-title {
          color: #EE1C24;
        }
      }

      .delivery-label {
        font-family: $fontText;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;

        text-align: center;
        letter-spacing: 0.04em;
        text-transform: uppercase;

        color: #80879A;
        margin: 0;
        margin-bottom: 32px;
      }

      .delivery-title {
        font-family: $fontTitle;
        font-style: normal;
        font-weight: normal;
        font-size: 48px;
        line-height: 100%;

        text-align: center;

        color: #383E4E;
        margin: 0;
      }
    }
  }
}

</style>
