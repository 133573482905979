<template>
  <div class="choose-tempo-container flex-start">
    <div class="tempo-header-steps">
      <p class="macaron-step">ÉTAPE 3</p>
      <p class="step-state">Question 2/4</p>
      <h1 class="medium-title choose-tempo-heading">Voici la toile correspondante : </h1>
    </div>
    <div class="resume-command">
      <div class="resume-img-container">
        <img :src=" '../../../img/types/' + order.order.image + '.jpg' " alt="">
      </div>
      <div class="resume-command-infos">
        <h3 class="medium-title resume-command-title">Toile Tempo pour {{ category }}</h3>
        <p class="regular-text resume-command-specs">{{ description }} - Ref : {{ refProduct }}</p>
        <p class="small-text resume-size">Largeur : {{ order.width }}’</p>
        <p class="small-text resume-size">Longueur : {{ order.length }}’</p>
        <p class="small-text resume-size">Hauteur : {{ order.height }}”</p>
      </div>
      <div class="resume-command-total">
        <div class="resume-command-number">
          <div @click.prevent="minusOne" class="minus-one">-</div>
          <input type="number" :value=" quantity " disabled>
          <div @click.prevent="plusOne" class="plus-one">+</div>
        </div>
        <p class="big-text resume-command-price">{{ newPrice }}$</p>
      </div>
    </div>

<!--    <div class="checkbox-verification">-->
<!--      <input checked id="sameBils" type="checkbox" v-model="checked">-->
<!--      <label for="sameBils">Je certifie que les dimensions sélectionnées sont exactes. Pour plus d’informations, accédez à nos conditions de vente.</label>-->
<!--    </div>-->

    <div class="navbar-wrapper">
      <a @click.prevent="handlePrev" href="#" class="site-btn is-revert">Ce n’est pas la bonne toile</a>
      <a @click.prevent="handleOrder" href="#" class="site-btn">Je souhaite acheter cette toile</a>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: {
      appState: {
        type: Number,
        default: 2
      },
      order: Object
    },
    data() {
      return {
        step: 7,
        quantity: 1,
        price: '',
        refProduct: '',
        description: '',
        category: '',
        newPrice: '',
        prevStep: '',
        nextStep: ''
      }
    },
    computed: {
      ...mapState('app', ['products']),
    },
    mounted() {
      this.hasMultipleSize()
    },
    methods: {
      minusOne() {
        if (this.quantity >= 1) {
          this.quantity -= 1
          this.newPrice = this.price * this.quantity
        }
      },
      plusOne() {
        this.quantity += 1
        this.newPrice = this.price * this.quantity
      },
      handlePrev() {
        this.prevStep = this.step - 1

        this.$emit('updateOrder', { }, this.prevStep)
      },
      handleOrder() {
        this.nextStep = this.step + 1
        this.$emit('updateOrder', {quantity: this.quantity, price: this.newPrice, ref: this.refProduct, category: this.category, description: this.description}, this.nextStep)
      },
      hasMultipleSize() {
        const sortedProducts = this.products.filter(el => {
          return el.category === this.order.order.data_name && el.width === this.order.width && el.length === this.order.length && el.height === this.order.height
        });

        this.price = parseFloat(sortedProducts[0].price)
        this.newPrice = parseFloat(sortedProducts[0].price)
        this.refProduct = sortedProducts[0].refProduct
        this.description = sortedProducts[0].description
        this.category =sortedProducts[0].category



      }
    }
  }
</script>

<style lang="scss">
  .help-box {
    background: #E9EBF1;
    border: 1px solid #D3D8E7;
    border-radius: 4px;
    margin-top: 16px;
    margin-bottom: 64px;
    padding: 24px;

    .help-box-heading {
      font-family: $fontText;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 100%;

      text-align: center;

      color: #383E4E;
      margin: 0;
    }

    .help-box-text {
      font-family: $fontText;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: #80879A;
      margin: 0;
    }

    .help-box-link {
      font-family: $fontText;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: #80879A;
      text-decoration: underline;
    }
  }
  .widths-wrapper{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 467px;
    margin: 0 auto;

    .width-content {
      width: calc(100% / 2 - 16px);
      margin-right: 16px;
      margin-bottom: 16px;
      min-height: 181px;
      transition: transform .3s ease-in-out;
      cursor: pointer;



      &:hover {
        transform: scale(1.06);
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      .width-wrapper {
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        box-sizing: border-box;
        border-radius: 8px;
        height: 100%;
        padding: 16px;

        &.is-selected {
          background: #FFE2E2;
          border: 1px solid #EE1C24;

          .width-label {
            color: #EE1C24;
          }

          .width-title {
            color: #EE1C24;
          }
        }

        .width-label {
          font-family: $fontText;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 150%;

          text-align: center;
          letter-spacing: 0.1em;
          text-transform: uppercase;

          color: #80879A;
          margin: 0;
          margin-bottom: 32px;
        }

        .width-title {
          font-family: $fontTitle;
          font-style: normal;
          font-weight: normal;
          font-size: 48px;
          line-height: 100%;

          text-align: center;

          color: #383E4E;
          margin: 0;
        }
      }
    }
  }
  .contact-action {
    margin-top: 32px;
    max-width: 470px;
    padding-bottom: 126px;
    text-align: center;
    .contact-action-link {
      font-family: $fontText;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;

      color: #6E6E7A;
      margin: 0;


      span {
        text-decoration: underline;
      }
    }
  }
</style>
