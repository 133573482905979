<template>
  <div class="stepsline-container">
    <img alt="logo-tempo" class="logo-tempo" src="@/assets/img/logo_white.svg" />
    <div class="stepsline-steps">
      <div class="stepsline-step" :class="{ 'is-inactive': appState !== index + 1 }" :key="index" v-for="(item, index) in items">
        <span class="step-circle">0{{ index + 1 }}</span>
        <p class="step-label">{{ item.label }}</p>
      </div>
    </div>
    <a
      class="contact-box"
      rel="noopener"
      target="_blank"
      href="https://abristempo.com/en/contact-us/">
      <svg width="37" height="37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18.5" cy="18.5" r="18.5" fill="#EE1C24"/>
        <path d="M12.719 14.646c0-2.93 2.466-5.396 5.396-5.396 2.926 0 5.387 2.457 5.396 5.382v.014c.014 2.26-1.386 3.667-2.53 4.707-.572.522-1.103.99-1.447 1.447-.344.457-.52.87-.52 1.448v.492a.902.902 0 0 1-.258.644.907.907 0 0 1-1.282 0 .902.902 0 0 1-.259-.644v-.492c0-1.007.381-1.862.886-2.53.504-.667 1.117-1.196 1.672-1.7 1.11-1.008 1.953-1.812 1.939-3.372 0-1.895-1.702-3.597-3.597-3.597s-3.597 1.702-3.597 3.597a.903.903 0 0 1-.259.644.907.907 0 0 1-1.282 0 .903.903 0 0 1-.258-.644zM16.766 26.337a1.35 1.35 0 1 1 2.699 0 1.35 1.35 0 0 1-2.7 0z" fill="#fff"/>
      </svg>
      <div class="texts-wrapper">
        <p class="contact-title">Besoin d’aide ? </p>
        <p class="contact-text">Contactez-nous</p>
      </div>
    </a>
  </div>
</template>

<script>
  export default {
    props: {
      appState: {
        type: Number,
        default: 2
      }
    },
    data() {
      return {
        items: [
          {
            label: 'Informations',
            number: '01'
          },
          {
            label: 'Catégorie',
            number: '02'
          },
          {
            label: 'Dimensions',
            number: '03'
          },
          {
            label: 'Paiement',
            number: '04'
          }
        ]
      }
    }
  }
</script>

<style lang="scss">
  .stepsline-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow-y: scroll;
    align-items: flex-start;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
    }

    .logo-tempo {
      /*position: absolute;*/
      /*left: 40px;*/
      /*top: 40px;*/
      margin-bottom: 3rem;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .contact-box {
    display: flex;
    align-items: center;
    background: #FFF7F7;
    border-radius: 4px;
    background-color: $white;
    padding: 18px 24px;
    padding-right: 83px;
    margin-top: 104px;
    width: 100%;

    svg {
      display: block;
      width: 100%;
      max-width: 37px;
      margin-right: 19px;
    }

    p {
      color: $colorAccent1;
      font-family: $fontText;
      font-style: normal;
      font-size: 16px;
      line-height: 100%;
      padding: 0;
      margin: 0;

      &.contact-title {
        font-weight: bold;
        margin-bottom: 8px;
      }

      &.contact-text {
        font-weight: normal;
      }
    }
  }

  .stepsline-steps {
    position: relative;
  }

  .stepsline-step {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 50px;

    &:last-of-type {
      margin-bottom: 0;
    }

    @media (max-width: 768px) {
      width: calc(50% - 15px);
      margin-right: 50px;

      &:nth-of-type(even) {
        margin-right: 0;
      }

      &:last-of-type {
        margin-bottom: 50px;
      }
    }

    @media (max-width: 500px) {
      width: 100%;
      margin-right: 0;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &.is-inactive {
      opacity: .5;
    }

    .step-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 2;
      width: 48px;
      height: 48px;
      border-radius: 8px;
      background: $white;
      color: $colorAccent1;
      font-family: $fontText;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
    }

    .step-label {
      font-family: $fontText;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;

      margin: 0 0 0 25px;
      color: $white;
    }
  }
</style>
