import ProductDB from '@/firebase/product-db'
import firebase from 'firebase/app'

export default {
  /**
   * Fetch products of current loggedin user
   */
  getUserProducts: ({ commit }) => {
    console.log(':tada: :tada: :tada: :tada: :tada:')
    firebase.firestore().collection('products').get()
      .then(
        (snapshot) => {
          console.log(snapshot)

          const products = snapshot.map(doc => {
            return {
              id: doc.id,
              ...doc.data()
            }
          })

          commit('setProducts', products)
        }
      )
      .catch(
        (error) => {
          console.log(error)
        }
      )

  },

  /**
   * Create a product for current loggedin user
   */
  createUserProduct: async ({ commit }, product) => {
    const userProductDb = new ProductDB()

    commit('setProductCreationPending', true)
    const createdProduct = await userProductDb.create(product)
    commit('addProduct', createdProduct)
    commit('setProductCreationPending', false)
  },

  /**
   * Create a new product for current loggedin user and reset product name input
   */
  triggerAddProductAction: ({ dispatch, state, commit }) => {
    if (state.productNameToCreate === '') return

    const product = { name: state.productNameToCreate }
    commit('setProductNameToCreate', '')
    dispatch('createUserProduct', product)
  },

  /**
   * Delete a user product from its id
   */
  deleteUserProduct: async ({ commit, getters }, productId) => {
    if (getters.isProductDeletionPending(productId)) return

    const userProductsDb = new ProductDB()

    commit('addProductDeletionPending', productId)
    await userProductsDb.delete(productId)
    commit('removeProductById', productId)
    commit('removeProductDeletionPending', productId)
  }
}
