<template>
  <div class="choose-tempo-container flex-start">
    <div class="tempo-header-steps">
      <p class="macaron-step">ÉTAPE 3</p>
      <div class="help-box">
        <h2 class="help-box-heading">Aide</h2>
        <p class="help-box-text">Besoin d’aide pour calculer les dimensions de la toile ?</p>
        <a     @click.prevent="openDocs()"
               class="help-box-link">Accéder à la documentation</a>
      </div>
      <h1 class="medium-title choose-tempo-heading">Veuillez <strong>confirmer</strong> les dimensions choisies : </h1>
    </div>

    <img class="sizes-images"
         v-if="order.order.image === 'abri-monopente' ||
               order.order.image === 'abri-portique' ||
               order.order.image === 'abri-rangement' ||
               order.order.image === 'abri-rangement-monopente' ||
               order.order.image === 'abri-simple-12-TA' ||
               order.order.image === 'abri-simple-pointu' ||
               order.order.image === 'abri-simple-rond' ||
               order.order.image === 'abri-simple-utilitaire' ||
               order.order.image === 'abri-vestibule'"
         :src=" '../../../img/sizes/' + order.order.image + '.png' "
         alt="">

    <img class="sizes-images"
         v-if="order.order.image === 'abri-double-pointu' && order.width === '20\''"
         :src=" '../../../img/sizes/' + order.order.image + '-tar-20.png' "
         alt="">

    <img class="sizes-images"
         v-if="order.order.image === 'abri-double-pointu' && order.width === '16\''"
         :src=" '../../../img/sizes/' + order.order.image + '-tar-16.png' "
         alt="">

    <img class="sizes-images"
         v-if="order.order.image === 'abri-double-pointu' && order.width === '18\''"
         :src=" '../../../img/sizes/' + order.order.image + '-tar-18.png' "
         alt="">

    <img class="sizes-images"
         v-if="order.order.image === 'abri-double-rond-7' && order.width === '18\''"
         :src=" '../../../img/sizes/' + order.order.image + '-18.png' "
         alt="">

    <img class="sizes-images"
         v-if="order.order.image === 'abri-double-rond-7' && order.width === '20\''"
         :src=" '../../../img/sizes/' + order.order.image + '-20.png' "
         alt="">

    <img class="sizes-images"
         v-if="order.order.image === 'abri-double-rond-8' && order.width === '18\''"
         :src=" '../../../img/sizes/' + order.order.image + '-18.png' "
         alt="">

    <img class="sizes-images"
         v-if="order.order.image === 'abri-double-rond-8' && order.width === '20\''"
         :src=" '../../../img/sizes/' + order.order.image + '-20.png' "
         alt="">

    <p class="home-content-text">Avant de passer à la commande, veuillez vérifier que la ou les dimensions sélectionnées précedemment sont exacte(s). Vous pouvez vous référer à l’image ci-dessus.</p>

    <div class="sizes-wrapper">
      <div v-if="order.order.image === 'abri-monopente' ||
         order.order.image === 'abri-rangement-monopente' ||
         order.order.image === 'abri-simple-12-TA' ||
         order.order.image === 'abri-simple-pointu' ||
         order.order.image === 'abri-simple-rond' ||
         order.order.image === 'abri-double-pointu' ||
         order.order.image === 'abri-double-rond-7' ||
         order.order.image === 'abri-double-rond-8'" class="height-content">
        <div class="height-wrapper is-red">
          <h2 class="height-label">LONGUEUR de toile</h2>
          <h2 class="height-title">{{order.length}}</h2>
        </div>
      </div>

      <div v-if="order.order.image === 'abri-monopente' ||
         order.order.image === 'abri-rangement' ||
         order.order.image === 'abri-rangement-monopente' ||
         order.order.image === 'abri-simple-12-TA' ||
         order.order.image === 'abri-simple-pointu' ||
         order.order.image === 'abri-double-pointu'" class="height-content">
        <div class="height-wrapper is-blue">
          <h2 class="height-label">HAUTEUR de toile</h2>
          <h2 class="height-title">{{order.height}}</h2>
        </div>
      </div>
    </div>



    <div class="checkbox-verification confirm-checkbox" style="padding-bottom: 100px;">
      <input checked id="sameBils" type="checkbox" v-model="checked">
      <label for="sameBils">Je certifie que les dimensions sélectionnées sont exactes et que je possède bien un abris de marque Tempo. Pour plus d’informations, accédez à nos <a href="https://abristempo.com/garanties/" target="_blank">conditions de vente</a>.</label>
    </div>

    <div class="navbar-wrapper">
      <a @click.prevent="handlePrev" href="#" class="site-btn is-revert">Ce ne sont pas les bonnes dimensions</a>
      <a @click.prevent="handleOrder" href="#" class="site-btn is-grey">Connaître le prix</a>
    </div>

    <div class="pop-up-content-docs">
      <div class="pop-up-overlay"></div>
      <svg class="close-pop-up" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.001 512.001">
        <path d="M284.286 256.002L506.143 34.144c7.811-7.811 7.811-20.475 0-28.285-7.811-7.81-20.475-7.811-28.285 0L256 227.717 34.143 5.859c-7.811-7.811-20.475-7.811-28.285 0-7.81 7.811-7.811 20.475 0 28.285l221.857 221.857L5.858 477.859c-7.811 7.811-7.811 20.475 0 28.285a19.938 19.938 0 0 0 14.143 5.857 19.94 19.94 0 0 0 14.143-5.857L256 284.287l221.857 221.857c3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857c7.811-7.811 7.811-20.475 0-28.285L284.286 256.002z"/>
      </svg>
      <div class="pop-up-content">
        <!--        <img :src="'/img/sizes/' + order.order.image + '-tar-20.png'" alt="" class="pop-up-img">-->
        <img class="pop-up-img"
             v-if="order.order.image === 'abri-monopente' ||
               order.order.image === 'abri-portique' ||
               order.order.image === 'abri-rangement' ||
               order.order.image === 'abri-rangement-monopente' ||
               order.order.image === 'abri-simple-12-TA' ||
               order.order.image === 'abri-simple-pointu' ||
               order.order.image === 'abri-simple-rond' ||
               order.order.image === 'abri-simple-utilitaire' ||
               order.order.image === 'abri-vestibule'"
             :src=" '../../../img/sizes/' + order.order.image + '.png' "
             alt="">

        <img class="pop-up-img"
             v-if="order.order.image === 'abri-double-pointu'"
             :src=" '../../../img/sizes/' + order.order.image + '-tar-20.png' "
             alt="">

        <img class="pop-up-img"
             v-if="order.order.image === 'abri-double-pointu'"
             :src=" '../../../img/sizes/' + order.order.image + '-tar-16.png' "
             alt="">

        <img class="pop-up-img"
             v-if="order.order.image === 'abri-double-pointu'"
             :src=" '../../../img/sizes/' + order.order.image + '-tar-18.png' "
             alt="">

        <img class="pop-up-img"
             v-if="order.order.image === 'abri-double-rond-7'"
             :src=" '../../../img/sizes/' + order.order.image + '-18.png' "
             alt="">

        <img class="pop-up-img"
             v-if="order.order.image === 'abri-double-rond-7'"
             :src=" '../../../img/sizes/' + order.order.image + '-20.png' "
             alt="">

        <img class="pop-up-img"
             v-if="order.order.image === 'abri-double-rond-8'"
             :src=" '../../../img/sizes/' + order.order.image + '-18.png' "
             alt="">

        <img class="pop-up-img"
             v-if="order.order.image === 'abri-double-rond-8'"
             :src=" '../../../img/sizes/' + order.order.image + '-20.png' "
             alt="">
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: {
      appState: {
        type: Number,
        default: 2
      },
      order: Object
    },
    data() {
      return {
        step: 6,
        checked: false,
        prevStep: '',
        nextStep: ''
      }
    },
    computed: {
      ...mapState('app', ['products']),
    },

    methods: {
      handlePrev() {
        this.prevStep = this.step - 1

        this.$emit('updateOrder', { }, this.prevStep)
      },
      openDocs() {

        const $formModalContact = document.querySelector('.pop-up-content-docs');
        const $formModalOverlayContact = document.querySelector('.pop-up-overlay');
        const $formModalCloseContact = document.querySelector('.close-pop-up');


        $formModalContact.classList.add('is-active');

        $formModalCloseContact.addEventListener('click', () => {
          $formModalContact.classList.remove('is-active');
        });

        $formModalOverlayContact.addEventListener('click', () => {
          $formModalContact.classList.remove('is-active');
        });

      },
      handleOrder() {
        if (this.checked) {
          this.nextStep = this.step + 1
          this.$emit('updateOrder', { certify: this.checked}, this.nextStep)
        }
      },
    }
  }
</script>

<style lang="scss">
  .sizes-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 723px;
    min-height: 190px;
    margin: 0 auto;
    margin-top: 24px;
    margin-bottom: 44px;

    .height-content {
      width: calc(100% / 2 - 16px);
      margin-right: 16px;
      margin-bottom: 16px;
      min-height: 181px;
      transition: transform .3s ease-in-out;
      cursor: pointer;
      max-width: 227px;

      &:nth-child(2n) {
        margin-right: 0;
      }

      .height-wrapper {
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        box-sizing: border-box;
        border-radius: 8px;
        height: 100%;
        padding: 16px;

        &.is-red {
          border: 1px solid #EE1C24;

          .height-label {
            color: #EE1C24;
          }

          .height-title {
            color: #EE1C24;
          }
        }

        &.is-blue {
          border: 1px solid #1D50D4;

          .height-label {
            color: #1D50D4;
          }

          .height-title {
            color: #1D50D4;
          }
        }

        .height-label {
          font-family: $fontText;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 150%;

          text-align: center;
          letter-spacing: 0.1em;
          text-transform: uppercase;

          color: #80879A;
          margin: 0;
          margin-bottom: 32px;
        }

        .height-title {
          font-family: $fontTitle;
          font-style: normal;
          font-weight: normal;
          font-size: 48px;
          line-height: 100%;

          text-align: center;

          color: #383E4E;
          margin: 0;
        }
      }
    }
  }

  .sizes-images {
    display: block;
    width: 100%;
    max-width: 723px;
  }
  .help-box {
    background: #E9EBF1;
    border: 1px solid #D3D8E7;
    border-radius: 4px;
    margin-top: 16px;
    margin-bottom: 64px;
    padding: 24px;

    .help-box-heading {
      font-family: $fontText;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 100%;

      text-align: center;

      color: #383E4E;
      margin: 0;
    }

    .help-box-text {
      font-family: $fontText;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: #80879A;
      margin: 0;
    }

    .help-box-link {
      font-family: $fontText;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: #80879A;
      text-decoration: underline;
    }
  }
  .widths-wrapper{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 467px;
    margin: 0 auto;

    .width-content {
      width: calc(100% / 2 - 16px);
      margin-right: 16px;
      margin-bottom: 16px;
      min-height: 181px;
      transition: transform .3s ease-in-out;
      cursor: pointer;



      &:hover {
        transform: scale(1.06);
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      .width-wrapper {
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        box-sizing: border-box;
        border-radius: 8px;
        height: 100%;
        padding: 16px;

        &.is-selected {
          background: #FFE2E2;
          border: 1px solid #EE1C24;

          .width-label {
            color: #EE1C24;
          }

          .width-title {
            color: #EE1C24;
          }
        }

        .width-label {
          font-family: $fontText;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 150%;

          text-align: center;
          letter-spacing: 0.1em;
          text-transform: uppercase;

          color: #80879A;
          margin: 0;
          margin-bottom: 32px;
        }

        .width-title {
          font-family: $fontTitle;
          font-style: normal;
          font-weight: normal;
          font-size: 48px;
          line-height: 100%;

          text-align: center;

          color: #383E4E;
          margin: 0;
        }
      }
    }
  }
  .contact-action {
    margin-top: 32px;
    max-width: 470px;
    padding-bottom: 126px;
    text-align: center;
    .contact-action-link {
      font-family: $fontText;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;

      color: #6E6E7A;
      margin: 0;


      span {
        text-decoration: underline;
      }
    }
  }
</style>
