import state from './orders.state'
import mutations from './orders.mutations'
import actions from './orders.actions'


export default {
  namespaced: true,
  state,
  mutations,
  actions
}
