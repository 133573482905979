<template>
  <div class="choose-tempo-container">
    <div class="tempo-header-steps">
      <p class="macaron-step">ÉTAPE 2</p>
      <p class="step-state">Question 3/4</p>
      <h1 class="medium-title choose-tempo-heading">Quelle <strong>longueur</strong> de toile
        recherchez-vous ? </h1>
      <div class="help-box">
        <h2 class="help-box-heading">Aide</h2>
        <p class="help-box-text">Besoin d’aide pour calculer les dimensions de la toile ?</p>
        <a     @click.prevent="openDocs()"
               class="help-box-link">Accéder à la documentation</a>
      </div>
    </div>
    <div class="lengths-wrapper" >
      <div @click="setLength(item)" class="length-content" :key="index" v-for="(item, index) in lengths">
        <div class="length-wrapper" :class="{'is-selected' :(item === length) }">
          <h2 class="length-label">Longueur de toile</h2>
          <h2 class="length-title">{{ item }}</h2>
        </div>
      </div>
      <div class="contact-action">
        <a
          rel="noopener"
          class="contact-action-link"
          target="_blank"
          href="https://abristempo.com/en/contact-us/">Si aucune des dimensions proposées ci-dessus ne convient,
          <span>contactez-nous</span> pour obtenir de l’aide.</a>
      </div>
    </div>
    <div class="navbar-wrapper">
      <a @click.prevent="handlePrev" href="#" class="site-btn is-revert">Précédent</a>
      <a @click.prevent="handleOrder" href="#" class="site-btn">Continuer</a>
    </div>
    <div class="pop-up-content-docs">
      <div class="pop-up-overlay"></div>
      <svg class="close-pop-up" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.001 512.001">
        <path d="M284.286 256.002L506.143 34.144c7.811-7.811 7.811-20.475 0-28.285-7.811-7.81-20.475-7.811-28.285 0L256 227.717 34.143 5.859c-7.811-7.811-20.475-7.811-28.285 0-7.81 7.811-7.811 20.475 0 28.285l221.857 221.857L5.858 477.859c-7.811 7.811-7.811 20.475 0 28.285a19.938 19.938 0 0 0 14.143 5.857 19.94 19.94 0 0 0 14.143-5.857L256 284.287l221.857 221.857c3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857c7.811-7.811 7.811-20.475 0-28.285L284.286 256.002z"/>
      </svg>
      <div class="pop-up-content">
        <!--        <img :src="'/img/sizes/' + order.order.image + '-tar-20.png'" alt="" class="pop-up-img">-->
        <img class="pop-up-img"
             v-if="order.order.image === 'abri-monopente' ||
               order.order.image === 'abri-portique' ||
               order.order.image === 'abri-rangement' ||
               order.order.image === 'abri-rangement-monopente' ||
               order.order.image === 'abri-simple-12-TA' ||
               order.order.image === 'abri-simple-pointu' ||
               order.order.image === 'abri-simple-rond' ||
               order.order.image === 'abri-simple-utilitaire' ||
               order.order.image === 'abri-vestibule'"
             :src=" '../../../img/sizes/' + order.order.image + '.png' "
             alt="">

        <img class="pop-up-img"
             v-if="order.order.image === 'abri-double-pointu'"
             :src=" '../../../img/sizes/' + order.order.image + '-tar-20.png' "
             alt="">

        <img class="pop-up-img"
             v-if="order.order.image === 'abri-double-pointu'"
             :src=" '../../../img/sizes/' + order.order.image + '-tar-16.png' "
             alt="">

        <img class="pop-up-img"
             v-if="order.order.image === 'abri-double-pointu'"
             :src=" '../../../img/sizes/' + order.order.image + '-tar-18.png' "
             alt="">

        <img class="pop-up-img"
             v-if="order.order.image === 'abri-double-rond-7'"
             :src=" '../../../img/sizes/' + order.order.image + '-18.png' "
             alt="">

        <img class="pop-up-img"
             v-if="order.order.image === 'abri-double-rond-7'"
             :src=" '../../../img/sizes/' + order.order.image + '-20.png' "
             alt="">

        <img class="pop-up-img"
             v-if="order.order.image === 'abri-double-rond-8'"
             :src=" '../../../img/sizes/' + order.order.image + '-18.png' "
             alt="">

        <img class="pop-up-img"
             v-if="order.order.image === 'abri-double-rond-8'"
             :src=" '../../../img/sizes/' + order.order.image + '-20.png' "
             alt="">
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    props: {
      appState: {
        type: Number,
        default: 2
      },
      order: Object
    },
    data() {
      return {
        step: 4,
        length: '',
        nextStep: '',
        prevStep: '',
        lengths: []
      }
    },
    computed: {
      ...mapState('app', ['products']),
    },
    mounted() {
      this.hasMultipleSize()
    },
    methods: {
      setLength(length) {
        this.length = length
        this.nextStep = this.step + 1
      },
      handlePrev() {

        this.prevStep = this.step - 1

        this.$emit('updateOrder', { }, this.prevStep)
      },
      handleOrder() {
        if (this.length !== '') {
          this.$emit('updateOrder', {length: this.length}, this.nextStep)
        }

      },
      openDocs() {

        const $formModalContact = document.querySelector('.pop-up-content-docs');
        const $formModalOverlayContact = document.querySelector('.pop-up-overlay');
        const $formModalCloseContact = document.querySelector('.close-pop-up');


        $formModalContact.classList.add('is-active');

        $formModalCloseContact.addEventListener('click', () => {
          $formModalContact.classList.remove('is-active');
        });

        $formModalOverlayContact.addEventListener('click', () => {
          $formModalContact.classList.remove('is-active');
        });

      },
      hasMultipleSize() {

        const sortedProducts = this.products.filter(el => {
          return el.category === this.order.order.data_name && el.width === this.order.width
        })

        this.lengths = sortedProducts.reduce((lengths, el) => {
          lengths.push(el.length)
          return lengths
        }, []);

        this.lengths = [...new Set(this.lengths)];
        this.lengths.sort();
      }
    }
  }
</script>

<style lang="scss">
.help-box {
  background: #E9EBF1;
  border: 1px solid #D3D8E7;
  border-radius: 4px;
  margin-top: 16px;
  margin-bottom: 64px;
  padding: 24px;

  .help-box-heading {
    font-family: $fontText;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;

    text-align: center;

    color: #383E4E;
    margin: 0;
  }

  .help-box-text {
    font-family: $fontText;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #80879A;
    margin: 0;
  }

  .help-box-link {
    font-family: $fontText;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #80879A;
    text-decoration: underline;
  }
}
.lengths-wrapper{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 467px;
  margin: 0 auto;

  .length-content {
    width: calc(100% / 2 - 16px);
    margin-right: 16px;
    margin-bottom: 16px;
    min-height: 181px;
    transition: transform .3s ease-in-out;
    cursor: pointer;



    &:hover {
      transform: scale(1.06);
    }

    &:nth-child(2n) {
      margin-right: 0;
    }

    .length-wrapper {
      background: #FFFFFF;
      border: 1px solid #D9D9D9;
      box-sizing: border-box;
      border-radius: 8px;
      height: 100%;
      padding: 16px;

      &.is-selected {
        background: #FFE2E2;
        border: 1px solid #EE1C24;

        .length-label {
          color: #EE1C24;
        }

        .length-title {
          color: #EE1C24;
        }
      }

      .length-label {
        font-family: $fontText;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        color: #80879A;
        margin: 0;
        margin-bottom: 32px;
      }

      .length-title {
        font-family: $fontTitle;
        font-style: normal;
        font-weight: normal;
        font-size: 48px;
        line-height: 100%;

        text-align: center;

        color: #383E4E;
        margin: 0;
      }
    }
  }
}
</style>
