<template>
  <div class="choose-tempo-container">
    <div class="tempo-header-steps">
      <p class="macaron-step">ÉTAPE 2</p>
      <p class="step-state">Question 1/4</p>
      <h1 class="medium-title choose-tempo-heading">Pour quelle catégorie d’abri
        recherchez-vous une toile?</h1>
    </div>
    <div class="categories-wrapper" :key="index" v-for="(cat, index) in categories">
      <div class="category-content">
        <h2 class="small-title">{{ cat.type }}</h2>
        <div @click="handleOrder(prod)" class="product-wrapper" :key="i" v-for="(prod, i) in cat.products">
          <img :alt=" prod.image " class="product-img" :src=" '../../../img/types/' + prod.image + '.jpg' " />
          <p class="regular-text">{{ prod.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      appState: {
        type: Number,
        default: 2
      }
    },
    data() {
      return {
        step: 2,
        categories: [
          {
            type: 'Abris Simple',
            products: [
              {
                name: 'Abri Simple Pointu',
                image: 'abri-simple-pointu',
                data_name: 'Abri Simple Pointu'
              },
              {
                name: 'Abri Simple Rond',
                image: 'abri-simple-rond',
                data_name: 'Abri Simple Rond'
              },
              // {
              //   name: 'Abri Simple S12',
              //   image: 'abri-simple-S12',
              //   data_name: 'Abris Simple S12'
              // },
              {
                name: 'Abri Simple 12\' TA',
                image: 'abri-simple-12-TA',
                data_name: 'Abri Simple 12\' TA'
              },
            ]
          },
          {
            type: 'Abris Double',
            products: [
              {
                name: 'Abri Double Pointu',
                image: 'abri-double-pointu',
                data_name: 'Abri Double Pointu'
              },
              {
                name: 'Abri Double Rond 7\'',
                image: 'abri-double-rond-7',
                data_name: 'Abri Double Rond 7\''
              },
              {
                name: 'Abri Double Rond 8\'',
                image: 'abri-double-rond-8',
                data_name: 'Abri Double Rond 8\''
              },
              // {
              //   name: 'Abri Double Rond HD',
              //   image: 'abri-simple-rond-hd',
              //   data_name: 'Abri Double Rond HD'
              // }
            ]
          },
          {
            type: 'Autre type d’abris',
            products: [
              {
                name: 'Abri Monopente',
                image: 'abri-monopente',
                data_name: 'Abri Monopente'
              },
              {
                name: 'Abri de Rangement',
                image: 'abri-rangement',
                data_name: 'Abri de Rangement'
              },
              {
                name: 'Abri Rangement Monopente',
                image: 'abri-rangement-monopente',
                data_name: 'Abri Rangement Monopente'
              },
              {
                name: 'Abri Portique',
                image: 'abri-portique',
                data_name: 'Abri Portique'
              },
              {
                name: 'Abri Utilitaire Blanc',
                image: 'abri-utilitaire',
                data_name: 'Abri Utilitaire Blanc'
              },
              {
                name: 'Abri Vestibule',
                image: 'abri-vestibule',
                data_name: 'Abri Vestibule'
              },
            ]
          }
        ]
      }
    },

    methods: {
      handleOrder(product) {
        this.$emit('createOrder', product, this.step)
      }
    }
  }
</script>

<style lang="scss">
  .choose-tempo-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &.flex-start {
      justify-content: flex-start;
    }

    .categories-wrapper {
      width: 100%;


      .small-title {
        width: 100%;
      }

      .category-content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;


        .product-wrapper {
          width: calc( 100% / 4 - 16px) ;
          margin-right: 16px;
          margin-bottom: 32px;
          background: $white;
          border: 1px solid #F2F3F6;
          box-shadow: 0px 0.833333px 1.66667px #E1E0E3;
          border-radius: 8px;
          overflow: hidden;
          transform: scale(1);
          transition: transform .3s ease-in-out;
          cursor: pointer;

          @media(max-width: 768px) {
            width: calc( 100% / 2 - 16px) ;
          }

          &:hover {
            transform: scale(1.06);
          }

          .product-img {
            display: block;
            width: 100%;
          }

          p {
            font-family: $fontText;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;

            color: #6E6E7A;
            margin: 0;
            padding: 16px;
          }
        }
      }
    }

    .checkbox-verification {
      width: 100%;
      max-width: 100%;
      margin-top: 44px;
      justify-content: center;
      align-items: center;

      &.confirm-checkbox {
        max-width: 723px;
        margin: 0 auto;

        input {
          margin-right: 20px;
        }
      }
    }

  }

  .tempo-header-steps {
    text-align: center;

    .choose-tempo-heading {
      max-width: 433px;
      margin: 0 auto;
      margin-bottom: 24px;
    }

    .macaron-step {
      display: inline-block;
      font-family: $fontText;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;

      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: #EE1C24;
      padding: 4px 16px;
      margin: 0;
      background-color: #FFE2E2;
      border-radius: 49px;
      margin-bottom: 16px;
    }

    .step-state {
      display: block;
      font-family: $fontText;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;

      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: #6E6E7A;
      margin: 0;
      margin-bottom: 16px;
    }


  }
</style>
