<template>
  <div class="pay-tempo-container">
    <div class="tempo-header-steps">
      <p class="macaron-step">ÉTAPE 4</p>
      <p class="step-state">Question 3/5</p>
      <h1 class="medium-title pay-tempo-heading">Livraison à domicile</h1>
      <div class="help-box">
        <h2 class="help-box-heading">Un frais de 100$ CAD s'applique pour la livraison à domicile</h2>
      </div>
    </div>
    <div class="form-wrapper">
      <div class="input-wrapper half-two margin-right">
        <label for="fullname">Nom complet</label>
        <input :class="{ 'has-error': $v.fullname.$error }" id="fullname" type="text" v-model="$v.fullname.$model" placeholder="John Doe">
      </div>
      <div class="input-wrapper half-two">
        <label for="mail">Email</label>
        <input :class="{ 'has-error': $v.mail.$error }" id="mail" type="email" v-model.trim="$v.mail.$model" placeholder="johndoe@gmail.com">
      </div>
      <div class="input-wrapper half-two margin-right">
        <label for="address">Numéro et nom de rue</label>
        <input :class="{ 'has-error': $v.address.$error }" id="address" v-model.trim="$v.address.$model" type="text" placeholder="Numéro de voie et nom de la rue">
      </div>
      <div class="input-wrapper half-two">
        <label for="addressOptional">Appartement, bureau, etc. (optionnel)</label>
        <input :class="{ 'has-error': $v.addressOptional.$error }" id="addressOptional" v-model.trim="$v.addressOptional.$model" type="text" placeholder="Appartement, bureau, etc.">
      </div>
      <div class="input-wrapper">
        <label for="city">Ville</label>
        <input :class="{ 'has-error': $v.city.$error }" id="city" v-model.trim="$v.city.$model" type="text" placeholder="Nom de la ville">
      </div>
      <div class="input-wrapper half-three margin-right">
        <label for="country">Pays/Région</label>
        <select :class="{ 'has-error': $v.country.$error }" id="country" v-model.trim="$v.country.$model" name="country">
          <option value="Canada" selected>Canada</option>
        </select>
      </div>
      <div class="input-wrapper half-three margin-right">
        <label for="province">Province</label>
        <select :class="{ 'has-error': $v.province.$error }" id="province" v-model.trim="$v.province.$model" name="province">
          <option value="Alberta">Alberta</option>
          <option value="British Columbia<">British Columbia</option>
          <option value="Manitoba">Manitoba</option>
          <option value="New Brunswick">New Brunswick</option>
          <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
          <option value="Nova Scotia">Nova Scotia</option>
          <option value="Ontario">Ontario</option>
          <option value="Prince Edward Island">Prince Edward Island</option>
          <option selected value="Quebec">Quebec</option>
          <option value="Saskatchewan">Saskatchewan</option>
          <option value="Northwest Territories">Northwest Territories</option>
          <option value="Nunavut">Nunavut</option>
          <option value="Yukon">Yukon</option>
        </select>
      </div>
      <div class="input-wrapper half-three">
        <label for="postalCode">Code Postal</label>
        <input :class="{ 'has-error': $v.postalCode.$error }" id="postalCode" v-model.trim="$v.postalCode.$model" type="text" placeholder="Code Postal">
      </div>
      <div class="input-wrapper">
        <label for="phone">Téléphone (si besoin de vous contacter à propos de votre commande)</label>
        <input :class="{ 'has-error': $v.phone.$error }" id="phone" type="tel" v-model="$v.phone.$model" placeholder="Téléphone" autocomplete="off">
      </div>
    </div>
    <div class="navbar-wrapper">
      <a @click.prevent="handlePrev" href="#" class="site-btn is-revert">Précédent</a>
      <a @click.prevent="handleOrder" href="#" class="site-btn" >Continuer</a>
    </div>
  </div>
</template>

<script>
  // Vuelidate validators
  import { required, email } from "vuelidate/src/validators"

  // Custom validators
  const alpha = (value) => {
    return /^[ a-zA-ZÀ-ÿ-]*$/gm.test(value)
  }

  export default {
    props: {
      appState: {
        type: Number,
        default: 2
      },
      order: Object
    },
    data() {
      return {
        step: 10,
        fullname: '',
        mail: '',
        address: '',
        addressOptional: '',
        city: '',
        country: '',
        province: '',
        postalCode: '',
        phone: '',
        prevStep: '',
        nextStep: '',
        priceDelivery: ''
      }
    },
    validations: {
      fullname: {
        required,
        alpha
      },
      mail: {
        required,
        email
      },
      address: {
        required,
      },
      addressOptional: {},
      city: {
        required,
        alpha
      },
      country: {
        required
      },
      province: {
        required
      },
      postalCode: {
        required
      },
      phone: {
        required
      }
    },
    methods: {
      handlePrev() {
        this.prevStep = this.step - 1

        this.$emit('updateOrder', { }, this.prevStep)
      },
      handleOrder() {
        this.$v.$touch()

        if (!this.$v.$invalid) {

          this.nextStep = this.step + 1

          this.priceDelivery = (parseFloat(this.order.priceTTC, 10) + 100).toFixed(2)

          this.$emit('updateOrder', {fullname: this.fullname, mail: this.mail, address: this.address, addressOptional: this.addressOptional, city: this.city, country: this.country, province: this.province, postalCode: this.postalCode, phone: this.phone, priceTTC: this.priceDelivery}, this.nextStep)
        }
      }
    }
  }
</script>

<style lang="scss">

  .form-wrapper {
    width: 100%;
    max-width: 672px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 100px;

    .input-wrapper {
      width: 100%;
      margin-bottom: 24px;

      &.half-two {
        width: calc(100% / 2 - 18px);

        @media(max-width: 768px) {
          width: 100%;
        }
      }

      &.half-three {
        width: calc(100% / 3 - 24px);

        @media(max-width: 768px) {
          width: 100%;
        }
      }

      &.margin-right {
        margin-right: 36px;

        @media(max-width: 768px) {
          margin-right: 0;
        }
      }

      label {
        display: block;
        font-family: $fontText;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #575757;
        margin: 0;
        margin-bottom: 12px;
      }

      input,
      select {
        display: block;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        box-shadow: 0px 1px 2px #E1E0E3;
        border-radius: 8px;
        padding: 12px 20px;
        width: 100%;
        -webkit-appearance:none;

        &.has-error {
          box-shadow: inset 0 1px 8px rgba($error, 0.1);
          border: 1px solid rgba($error, .6);
        }
      }
    }
  }

</style>
