<template>
  <aside class="page-sidepanel">
    <div class="sidepanel-container">
      <slot></slot>
    </div>
  </aside>
</template>

<script>
  export default {

  }
</script>

<style lang="scss">
  .page-sidepanel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35vw;
    height: 100vh;
    background: $colorAccent1;
    box-shadow: 0 0 20px rgba(32, 32, 32, 0.3);

    &.is-active {
      display: flex;
    }

    &.is-none {
      display: none;
    }

    @media (max-width: 1024px) {
      width: 100vw;
      height: auto;
      transform: translateX(0);
      animation: none;
    }

    .sidepanel-container {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      padding: 40px;
      display: flex;
      align-items: center;


      @media (max-width: 1024px) {
        padding: 40px;
      }

      @media (max-width: 768px) {
        padding: 40px 20px;
        padding-bottom: 120px;
      }
    }
  }
</style>
