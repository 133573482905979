import firebase from 'firebase/app'
import 'firebase/auth'

// The configuration below is not sensitive data. You can serenely add your config here
const config = {
  apiKey: "AIzaSyAozfe50EmmG_Mi8reUFe2DTlOqPlxiqEE",
  authDomain: "tempo-test-c8875.firebaseapp.com",
  databaseURL: "https://tempo-test-c8875.firebaseio.com",
  projectId: "tempo-test-c8875",
  storageBucket: "tempo-test-c8875.appspot.com",
  messagingSenderId: "764301567322",
  appId: "1:764301567322:web:ff119594624109df87bf63"
}

firebase.initializeApp(config)
