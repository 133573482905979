<template>
  <div class="pay-tempo-container">
    <div class="tempo-header-steps">
      <p class="macaron-step">ÉTAPE 4</p>
      <p class="step-state">Question 3/5</p>
      <h1 class="medium-title pay-tempo-heading">Ramassage en magasin</h1>
    </div>
    <div class="form-wrapper">
      <h2 class="form-title">1. Complétez vos informations personnelles</h2>
      <div class="input-wrapper">
        <label for="fullname">Nom complet</label>
        <input :class="{ 'has-error': $v.fullname.$error }" id="fullname" type="text" v-model="$v.fullname.$model" placeholder="John Doe">
      </div>
      <div class="input-wrapper">
        <label for="mail">Email</label>
        <input :class="{ 'has-error': $v.mail.$error }" id="mail" type="email" v-model.trim="$v.mail.$model" placeholder="johndoe@gmail.com">
      </div>
      <div class="input-wrapper">
        <label for="phone">Téléphone</label>
        <input :class="{ 'has-error': $v.phone.$error }" id="phone" type="tel" v-model="$v.phone.$model" placeholder="Téléphone">
      </div>
      <h2 class="form-title">2. Choisissez votre point de ramassage</h2>
      <h3 class="form-subtitle">Magasins corporatifs</h3>
      <div class="shops-wrapper">
        <div @click="setShop(item.city, item.address)" class="shop-content" :class="{'is-selected' :(item.city === shopCity) }" :key="index" v-for="(item, index) in shops">
          <p class="shop-city">{{ item.city }}</p>
          <p class="shop-address">{{ item.address }}</p>
        </div>
      </div>
    </div>
    <div class="navbar-wrapper">
      <a @click.prevent="handlePrev" href="#" class="site-btn is-revert">Précédent</a>
      <a href="#" class="site-btn" @click.prevent="handleOrder">Continuer</a>
    </div>
  </div>
</template>

<script>
  // Vuelidate validators
  import { required, email } from "vuelidate/src/validators"

  // Custom validators
  const alpha = (value) => {
    return /^[ a-zA-ZÀ-ÿ-]*$/gm.test(value)
  }

  export default {
    props: {
      appState: {
        type: Number,
        default: 2
      }
    },
    data() {
      return {
        step: 10,
        fullname: '',
        mail: '',
        phone: '',
        shopCity: '',
        shopAdress: '',
        prevStep: '',
        nextStep: '',
        shops: [
          {
            city: 'Laval',
            address: '3265, rue Jules-Brillant, Laval, Québec, H7P 6C9'
          },
          {
            city: 'Montreal',
            address: '7970, rue Jarry Est Anjou, Québec, H1J 1H5'
          },
          {
            city: 'Saint-Jérôme',
            address: '629, rue de Martigny Ouest, Saint-Jérôme ,Québec, J5L 1Z6'
          },
          {
            city: 'Brossard',
            address: '9575, rue Ignace Brossard, Québec, J4Y 2P3'
          }
        ]
      }
    },
    validations: {
      fullname: {
        required,
        alpha
      },
      mail: {
        required,
        email
      },
      phone: {
        required
      }
    },
    methods: {
      setShop(city, address) {
        this.shopCity = city
        this.shopAdress = address
        this.nextStep = this.step + 1
      },
      handlePrev() {
        this.prevStep = this.step - 1

        this.$emit('updateOrder', { }, this.prevStep)
      },
      handleOrder() {
        this.$v.$touch()

        if (!this.$v.$invalid) {
          this.$emit('updateOrder', {fullname: this.fullname, mail: this.mail, phone: this.phone, shopCity: this.shopCity, shopAdress: this.shopAdress}, this.nextStep)
        }
      }
    }
  }
</script>

<style lang="scss">
.shops-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .shop-content {
    width: calc(100% / 2 - 16px);
    margin-right: 32px;
    margin-bottom: 32px;
    background: #FFFFFF;

    border: 1px solid #F2F3F6;
    border-radius: 8px;
    padding: 58px;
    cursor: pointer;

    &.is-selected {
      background: #FFF4F4;
      border: 1px solid #EE1C24;

      .shop-city,
      .shop-address {
        color: #EE1C24;
      }
    }

    &:nth-child(2n) {
      margin-right: 0;
    }

    .shop-city {
      font-family: $fontTitle;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 133%;
      text-align: center;
      color: #333333;
      align-self: center;
      margin: 0px 8px;
    }

    .shop-address {
      font-family: $fontText;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;

      /* or 24px */
      text-align: center;

      color: #6E6E7A;

      align-self: center;
      margin: 0px 8px;
    }
  }
}
  .form-wrapper {
    .form-title {
      display: block;
      font-family: $fontTitle;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 133%;
      color: #333333;
    }

    .form-subtitle {
      display: block;
      width: 100%;
      font-family: $fontText;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      /* identical to box height, or 21px */

      letter-spacing: 0.1em;
      text-transform: uppercase;

      /* Content/Headings */

      color: #333333;
    }
  }

</style>
