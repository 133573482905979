<template>
  <div class="choose-tempo-container flex-start">
    <div class="tempo-header-steps">
      <p class="macaron-step">ÉTAPE 4</p>
      <p class="step-state">Question 5/5</p>
      <h1 class="medium-title choose-tempo-heading">
        Confirmer votre commande
      </h1>
      <div class="help-box">
        <h2 class="help-box-heading final-price">
          PAIEMENT Total : {{ order.priceTTC }}$
        </h2>
      </div>
    </div>

    <section class="all-informations-wrapper">
      <div class="informations-content">
        <div class="information-top">
          <p class="information-title">Vos informations</p>
          <a
            href="#"
            class="information-modif"
            @click.prevent="goBackToStep(10)"
            >Modifier</a
          >
        </div>
        <div class="information-bottom">
          <p class="information-text">{{ order.fullname }}</p>
          <p class="information-text">{{ order.mail }}</p>
        </div>
      </div>

      <div class="informations-content">
        <div class="information-top">
          <p class="information-title">Adresse de livraison</p>
          <a
            href="#"
            class="information-modif"
            @click.prevent="goBackToStep(10)"
            >Modifier</a
          >
        </div>
        <div v-if="order.delivery === 'magasin'" class="information-bottom">
          <p class="information-text">{{ order.shopCity }}</p>
          <p class="information-text">{{ order.shopAdress }}</p>
        </div>
        <div v-if="order.delivery === 'domicile'" class="information-bottom">
          <p class="information-text">M. {{ order.fullname }}</p>
          <p class="information-text">{{ order.address }}</p>
          <p class="information-text">{{ order.addressOptional }}</p>
          <p class="information-text">
            {{ order.city }}, {{ order.province }} {{ order.postalCode }}
          </p>
          <p class="information-text">{{ order.country }}</p>
          <p class="information-text">{{ order.phone }}</p>
        </div>
      </div>

      <div class="informations-content">
        <div class="information-top">
          <p class="information-title">Paiement</p>
          <a
            href="#"
            class="information-modif"
            @click.prevent="goBackToStep(10)"
            >Modifier</a
          >
        </div>
        <div class="information-bottom">
          <p class="information-text">{{ order.mail }}</p>
        </div>
      </div>

      <div class="informations-content">
        <div class="information-top">
          <p class="information-title">Adresse de facturation</p>
          <a
            href="#"
            class="information-modif"
            @click.prevent="goBackToStep(11)"
            >Modifier</a
          >
        </div>
        <div class="information-bottom">
          <p class="information-text">
            M.
            {{
              order.billFullname !== '' ? order.billFullname : order.fullname
            }}
          </p>
          <p class="information-text">
            {{ order.billAddress !== '' ? order.billAddress : order.address }}
          </p>
          <p class="information-text">
            {{
              order.billAddressOptional !== ''
                ? order.billAddressOptional
                : order.addressOptional
            }}
          </p>
          <p class="information-text">
            {{ order.billCity !== '' ? order.billCity : order.city }},
            {{
              order.billProvince !== '' ? order.billProvince : order.province
            }}
            {{
              order.billPostalCode !== ''
                ? order.billPostalCode
                : order.postalCode
            }}
          </p>
          <p class="information-text">
            {{ order.billCountry !== '' ? order.billCountry : order.country }}
          </p>
          <p class="information-text">
            {{ order.billPhone !== '' ? order.billPhone : order.phone }}
          </p>
        </div>
      </div>
    </section>

    <div class="navbar-wrapper">
      <a href="#" class="site-btn is-revert" @click.prevent="handlePrev"
        >Précédent</a
      >
      <div class="paypal-container">
        <PayPal
          :amount="paypalPriceTTC"
          currency="CAD"
          :client="credentials"
          env="production"
          :items="myItems"
          @payment-authorized="paymentAuthorized"
          @payment-cancelled="paymentCancelled"
        >
        </PayPal>
      </div>
    </div>
  </div>
</template>

<script>
import PayPal from 'vue-paypal-checkout'

export default {
  components: {
    PayPal
  },
  props: {
    appState: {
      type: Number,
      default: 2
    },
    order: Object
  },
  data() {
    return {
      step: 12,
      prevStep: '',
      nextStep: '',
      unitPrice: '',
      paypalPriceTTC: '',
      paypal: {
        sandbox:
          'AflOKed65OozOhsk2LmraCivjIphrRrG0dd56XRdiOJNlOeWXJaLQpEn1_mU150mQhtE-SShI4p7l6j0',
        production:
          'AfxnxqF0FTV7eYvcOzde_MwJnyPCysro3S3CH_EulD2QjG61vzgZrJq7yN7_g5pBmXeB80bbRIkNedCG'
      },
      credentials: {
        sandbox:
          'AflOKed65OozOhsk2LmraCivjIphrRrG0dd56XRdiOJNlOeWXJaLQpEn1_mU150mQhtE-SShI4p7l6j0',
        production:
          'AfxnxqF0FTV7eYvcOzde_MwJnyPCysro3S3CH_EulD2QjG61vzgZrJq7yN7_g5pBmXeB80bbRIkNedCG'
      },
      myItems: [
        {
          name: '',
          description: '',
          quantity: '',
          price: '',
          currency: 'CAD'
        }
      ]
    }
  },
  mounted() {
    this.unitPrice = this.order.priceTTC / this.order.quantity
    this.unitPrice = parseFloat(this.unitPrice).toFixed(2)
    this.paypalPriceTTC = this.unitPrice * this.order.quantity
    this.paypalPriceTTC = this.paypalPriceTTC.toString()

    this.myItems.forEach(item => {
      item.name = this.order.description
      item.description = this.order.description
      item.quantity = this.order.quantity
      item.price = this.unitPrice
    })
  },
  methods: {
    goBackToStep(step) {
      this.prevStep = step

      this.$emit('updateOrder', {}, this.prevStep)
    },
    handlePrev() {
      this.prevStep = this.step - 1

      this.$emit('updateOrder', {}, this.prevStep)
    },
    handleOrder() {
      this.nextStep = this.step + 1
      this.$emit('updateOrder', this.nextStep)
    },
    paymentAuthorized(event) {
      // Your response
      console.log(event)
      this.nextStep = this.step + 1
      this.$emit('updateOrder', { isPaid: true }, this.nextStep)
    },
    paymentCancelled(event) {
      // Your response
      console.log(event)
      this.nextStep = this.step
      this.$emit('updateOrder', { isPaid: false }, this.nextStep)
    }
  }
}
</script>

<style lang="scss">
.paypal-container {
  position: relative;
  .paypal-button {
    /*opacity: 0;*/
  }
}

.all-informations-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .informations-content {
    width: calc(100% / 2 - 40px);
    margin-bottom: 48px;

    &:nth-child(odd) {
      margin-right: 80px;
    }

    @media (max-width: 768px) {
      width: 100%;
      margin-right: 0;

      &:nth-child(odd) {
        margin-right: 0;
      }
    }

    .information-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #d9d9d9;
      margin-bottom: 8px;

      .information-modif {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;

        text-align: right;

        color: #ee1c24;
        margin: 0;
      }

      .information-title {
        font-family: Arvo;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 133%;

        color: #333333;
        margin: 0;
      }
    }

    .information-bottom {
      .information-text {
        display: block;
        width: 100%;
        margin: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;

        color: #6e6e7a;
        margin-bottom: 8px;
      }
    }
  }
}
</style>
