<template>
  <section class="page-content">
    <slot></slot>
  </section>
</template>

<script>
  export default {

  }
</script>

<style lang="scss">
  .page-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    padding: 40px 60px;
    overflow-y: scroll;

    &.is-full {
      width: 100vw;
    }

    @media (max-width: 1024px) {
      width: 100vw;
      height: auto;
      padding: 40px;
      animation: none;
    }
  }
</style>
