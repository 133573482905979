<template>
  <section class="pay-tempo-container">
    <div class="tempo-header-steps">
      <p class="macaron-step">ÉTAPE 4</p>
      <p class="step-state">Question 4/5</p>
      <h1 class="medium-title pay-tempo-heading">Informations de paiement</h1>
    </div>

    <div class="checkbox-verification">
      <input checked id="sameBils" type="checkbox" v-model="checked">
      <label for="sameBils">Mes adresses de livraison et de facturation sont identiques.</label>
    </div>

    <div v-if="!checked" class="form-wrapper">
      <div class="input-wrapper half-two margin-right">
        <label for="fullname">Nom complet</label>
        <input :class="{ 'has-error': $v.billFullname.$error }" id="fullname" type="text" v-model="$v.billFullname.$model" placeholder="John Doe">
      </div>
      <div class="input-wrapper half-two">
        <label for="mail">Email</label>
        <input :class="{ 'has-error': $v.billMail.$error }" id="mail" type="email" v-model.trim="$v.billMail.$model" placeholder="johndoe@gmail.com">
      </div>
      <div class="input-wrapper half-two margin-right">
        <label for="address">Numéro et nom de rue</label>
        <input :class="{ 'has-error': $v.billAddress.$error }" id="address" v-model.trim="$v.billAddress.$model" type="text" placeholder="Numéro de voie et nom de la rue">
      </div>
      <div class="input-wrapper half-two">
        <label for="addressOptional">Appartement, bureau, etc. (optionnel)</label>
        <input :class="{ 'has-error': $v.billAddressOptional.$error }" id="addressOptional" v-model.trim="$v.billAddressOptional.$model" type="text" placeholder="Appartement, bureau, etc.">
      </div>
      <div class="input-wrapper">
        <label for="city">Ville</label>
        <input :class="{ 'has-error': $v.billCity.$error }" id="city" v-model.trim="$v.billCity.$model" type="text" placeholder="Nom de la ville">
      </div>
      <div class="input-wrapper half-three margin-right">
        <label for="country">Pays/Région</label>
        <select :class="{ 'has-error': $v.billCountry.$error }" id="country" v-model.trim="$v.billCountry.$model" name="country">
          <option value="ca" selected>Canada</option>
        </select>
      </div>
      <div class="input-wrapper half-three margin-right">
        <label for="province">Province</label>
        <select :class="{ 'has-error': $v.billProvince.$error }" id="province" v-model.trim="$v.billProvince.$model" name="province">
          <option value="AB">Alberta</option>
          <option value="BC">British Columbia</option>
          <option value="MB">Manitoba</option>
          <option value="NB">New Brunswick</option>
          <option value="NL">Newfoundland and Labrador</option>
          <option value="NS">Nova Scotia</option>
          <option value="ON">Ontario</option>
          <option value="PE">Prince Edward Island</option>
          <option selected value="QC">Quebec</option>
          <option value="SK">Saskatchewan</option>
          <option value="NT">Northwest Territories</option>
          <option value="NU">Nunavut</option>
          <option value="YT">Yukon</option>
        </select>
      </div>
      <div class="input-wrapper half-three">
        <label for="postalCode">Code Postal</label>
        <input :class="{ 'has-error': $v.billPostalCode.$error }" id="postalCode" v-model.trim="$v.billPostalCode.$model" type="text" placeholder="Code Postal">
      </div>
      <div class="input-wrapper">
        <label for="phone">Téléphone</label>
        <input :class="{ 'has-error': $v.billPhone.$error }" id="phone" type="tel" v-model="$v.billPhone.$model" placeholder="Téléphone">
      </div>
    </div>
    <div class="paiement-paypal">
      <div class="paypal-content-top">
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="12" fill="#1D50D4"/><path d="M9.482 16.6l-3.135-3.255a1.227 1.227 0 01.052-1.76 1.323 1.323 0 011.816.066l2.21 2.276 5.345-5.532c.497-.498 1.302-.53 1.816-.066.531.482.548 1.263.068 1.76l-6.287 6.512c-.24.25-.583.399-.943.399a1.352 1.352 0 01-.942-.4z" fill="#fff"/>
        </svg>
        <p class="paiement-label">Paiment via Paypal</p>
        <img class="logo-paypal" src="@/assets/img/paypal-logo.png" alt="">
      </div>
      <p class="paypal-text">Afin de poursuivre votre paiment via Paypal, veuillez cliquez sur le bouton ci-dessous. Vous serez redirigé vers la page de connexion dédiée.</p>
    </div>
    <div class="navbar-wrapper">
      <a @click.prevent="handlePrev" href="#" class="site-btn is-revert">Précédent</a>
      <a @click.prevent="handleOrder" href="#" class="site-btn">Continuer</a>
    </div>
  </section>
</template>

<script>
  // Vuelidate validators
  import { required,  email } from "vuelidate/src/validators"

  // Custom validators
  const alpha = (value) => {
    return /^[ a-zA-ZÀ-ÿ-]*$/gm.test(value)
  }

  export default {
    props: {
      appState: {
        type: Number,
        default: 2
      }
    },
    data() {
      return {
        checked: true,
        step: 11,
        billFullname: '',
        billMail: '',
        billAddress: '',
        billAddressOptional: '',
        billCity: '',
        billCountry: '',
        billProvince: '',
        billPostalCode: '',
        billPhone: '',
        prevStep: '',
        nextStep: ''
      }
    },
    validations: {
      billFullname: {
        required,
        alpha
      },
      billMail: {
        required,
        email
      },
      billAddress: {
        required
      },
      billAddressOptional: {},
      billCity: {
        required,
        alpha
      },
      billCountry: {
        required
      },
      billProvince: {
        required
      },
      billPostalCode: {
        required
      },
      billPhone: {
        required
      }
    },
    methods: {
      handlePrev() {
        this.prevStep = this.step - 1

        this.$emit('updateOrder', { }, this.prevStep)
      },
      handleOrder() {
        this.$v.$touch()
        if  (!this.checked) {
          if (!this.checked && !this.$v.$invalid) {
            this.nextStep = this.step + 1
            this.$emit('updateOrder', {billFullname: this.billFullname, billMail: this.billMail, billAddress: this.billAddress, billAddressOptional: this.billAddressOptional, billCity: this.billCity, billCountry: this.billCountry, billProvince: this.billProvince, billPostalCode: this.billPostalCode, billPhone: this.billPhone}, this.nextStep)
          }
        } else {
            this.nextStep = this.step + 1
            this.$emit('updateOrder', {billFullname: this.billFullname, billMail: this.billMail, billAddress: this.billAddress, billAddressOptional: this.billAddressOptional, billCity: this.billCity, billCountry: this.billCountry, billProvince: this.billProvince, billPostalCode: this.billPostalCode, billPhone: this.billPhone}, this.nextStep)
        }
      }
    }
  }
</script>

<style lang="scss">
  .checkbox-verification {
    display: flex;
    width: 100%;
    max-width: 672px;
    margin-bottom: 26px;

    &.confirm-checkbox {
      max-width: 723px;
      margin: 0 auto;

      input {
        margin-right: 20px;
      }
    }

    label {
      font-family: $fontText;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: #333333;
    }
  }

  .form-wrapper {
    &.is-none {
      display: none;
    }
  }

  .paiement-paypal {
    max-width: 672px;

    .paypal-btn {
      max-width: 239px;
    }

    .paypal-text {
      font-family: $fontText;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: #6E6E7A;
      margin-top: 26px;
      margin-bottom: 24px;
    }
    .paypal-content-top {
      display: flex;
      width: 100%;
      position: relative;

      .logo-paypal {
        position: absolute;
        top: -20px;
        right: 0;
        max-width: 190px;

        @media(max-width: 475px) {
          top: -10px;
          max-width: 110px;
        }
      }

      svg {
        display: block;
      }

      .paiement-label {
        width: 88%;
        font-family: $fontText;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #333333;
        padding: 0;
        margin: 0;
        margin-left: 10px;
      }
    }
  }
</style>
