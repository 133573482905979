<template>
  <section class="page-container">
    <page-content :class="{ 'is-full': appState === 1 }">
      <slot name="pageContent"></slot>
    </page-content>

    <page-sidepanel :class="{ 'is-none': appState === 1  }">
      <slot name="pageSidepanel"></slot>
    </page-sidepanel>
  </section>
</template>

<script>
  // Components
  import PageSidepanel from '@/components/structures/PageSidePanel'
  import PageContent from '@/components/structures/PageContent'

  export default {
    props: {
      appState: {
        type: Number,
        default: 1
      }
    },
    components: {
      PageSidepanel,
      PageContent
    }
  }
</script>

<style lang="scss">
  .page-container {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    flex-direction: row-reverse;

    @media (max-width: 1024px) {
      overflow: auto;
      flex-direction: column;
      height: 100%;
    }
  }
</style>
