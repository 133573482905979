<template>
  <div class="page-wrapper">

    <svg width="110" height="110" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M55 .476a54.55 54.55 0 0 0-38.554 15.969A54.553 54.553 0 0 0 .477 54.999c0 14.46 5.75 28.328 15.97 38.554A54.552 54.552 0 0 0 55 109.522a54.55 54.55 0 0 0 38.554-15.97A54.553 54.553 0 0 0 109.523 55a54.561 54.561 0 0 0-15.992-38.532A54.554 54.554 0 0 0 54.999.475L55 .476zm0 100.14A45.613 45.613 0 0 1 9.383 54.999 45.612 45.612 0 0 1 55 9.38 45.612 45.612 0 0 1 100.618 55a45.683 45.683 0 0 1-13.368 32.25A45.684 45.684 0 0 1 55 100.616z" fill="#25C251"/>
      <path d="M76.86 39.156l-27.64 28-16-16.344v.008a4.444 4.444 0 0 0-6.289-.047 4.444 4.444 0 0 0-.047 6.29l19.258 19.562v-.008c.297.265.633.476 1 .64.133.118.282.22.438.305a4.416 4.416 0 0 0 1.68.336c.578 0 1.148-.117 1.68-.336.195-.102.374-.227.538-.36.328-.156.633-.35.907-.585l30.82-31.196a4.468 4.468 0 0 0 .109-6.461 4.464 4.464 0 0 0-6.453.195v.001z" fill="#25C251"/>
    </svg>

    <h1 class="home-page-title">Votre commande est confirmée ! </h1>
    <p class="home-page-text">Vous allez recevoir un email contenant toutes les informations concernant votre commande.
      N’hésitez pas à nous contacter pour toute question. </p>
    <a
      rel="noopener"
      class="site-btn"
      target="_blank"
      href="https://abristempo.com/"
    >Retour au site web</a
    >
  </div>
</template>

<script>
  export default {
    props: {
      appState: {
        type: Number,
        default: 2
      },
      order: Object
    },
    data() {
      return {
        step: 13
      }
    }
  }
</script>

<style lang="scss">
</style>
